import React from "react"
import { Link } from "gatsby"
import { Flex, Title } from "../components/system"

const Thanks = () => (
  <Flex height="100vh" flexDirection="column">
    <Title>Thank You</Title>
    <Link to="/">Go back home</Link>
  </Flex>
)

export default Thanks
